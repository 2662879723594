:root {
  --swal-btn-confirm: #00acc1;

  --swal-btn-cancel: #efefef;

  --swal-btn-danger: #f44336;

  .swal-button {
    min-height: auto;
    min-width: auto;
    box-shadow: none;
    border: none;
    border-radius: 3px;
    position: relative;
    padding: 12px 30px;
    margin: 0.3125rem 1px;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0;
    will-change: box-shadow, transform;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
      background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;

    &--confirm {
      background-color: var(--swal-btn-confirm);
      box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14),
        0 3px 1px -2px rgba(0, 188, 212, 0.2),
        0 1px 5px 0 rgba(0, 188, 212, 0.12);
      &:not([disabled]):hover {
        background-color: var(--swal-btn-confirm);
      }
      &:active {
        background-color: var(--swal-btn-confirm);
      }
      &:hover {
        outline: none;
        box-shadow: 0 14px 26px -12px rgba(0, 188, 212, 0.42),
          0 4px 23px 0px rgba(0, 0, 0, 0.12),
          0 8px 10px -5px rgba(0, 188, 212, 0.2);
      }
    }

    &--danger {
      background-color: var(--swal-btn-danger);
      box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14),
        0 3px 1px -2px rgba(244, 67, 54, 0.2),
        0 1px 5px 0 rgba(244, 67, 54, 0.12);
      &:not([disabled]):hover {
        background-color: var(--swal-btn-danger);
      }
      &:active {
        background-color: var(--swal-btn-danger);
      }
      &:hover {
        box-shadow: 0 14px 26px -12px rgba(244, 67, 54, 0.42),
          0 4px 23px 0px rgba(0, 0, 0, 0.12),
          0 8px 10px -5px rgba(244, 67, 54, 0.2);
      }
    }

    &--cancel {
      background-color: var(--swal-btn-cancel);
      box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
        0 3px 1px -2px rgba(153, 153, 153, 0.2),
        0 1px 5px 0 rgba(153, 153, 153, 0.12);
      &:not([disabled]):hover {
        background-color: var(--swal-btn-cancel);
      }
      &:active {
        background-color: var(--swal-btn-cancel);
      }
      &:hover {
        box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42),
          0 4px 23px 0px rgba(0, 0, 0, 0.12),
          0 8px 10px -5px rgba(153, 153, 153, 0.2);
      }
    }
  }
}
